.App {
    background-color: #0C0D10;
    height: 100vh;
    overflow: scroll;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;
    scroll-behavior: auto;

}

.App p {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: white;
}

.App h1 {
  font-family: 'Playfair Display', serif;
  font-size: 76px;
  color: white;
  font-weight: bolder;
}

.App h2 {
    font-family: 'Playfair Display', serif;
    font-size: 22px;
    color: white;
    font-weight: bolder;
}

input[type=text] {
    width: 100%;
    padding: 15px 15px;
    box-sizing: border-box;
    border: 1px solid #3C4658;
    border-radius: 30px;
    background-color: transparent;
    outline: none;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: white;
  }

  input[type=text]:valid {
    background-color: transparent;
  }

  input[type=text]::after {
    background-color: transparent;
  }

  
  input[type=text]::placeholder {
    color: #3C4658;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    opacity: 1;
}

.section1 {
  scroll-snap-align: start;
}

.section2 {
  scroll-snap-align: start;
}

@media (max-width: 768px) {
  .App p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: white;
}

.App h1 {
  font-family: 'Playfair Display', serif;
  font-size: 52px;
  color: white;
  font-weight: bolder;
}

.App h2 {
    font-family: 'Playfair Display', serif;
    font-size: 22px;
    color: white;
    font-weight: bolder;
}
}