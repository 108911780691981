@keyframes slideDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.welcome-row {
    margin: 200px 100px;
    display: flex;
    gap: 100px;
   align-items: center;
}

.welcome-column {
    display: flex;
    flex-direction: column;
}

.welcome-column > * {
    margin-bottom: 50px;
}

.welcome-column > *:last-child {
    margin-bottom: 0;
}

.welcome .image {
    width: 100%;
    animation: slideDown 1s ease-out forwards;
}

.welcome .preview {
    width: 100%;
}

@media (max-width: 768px) {
    .welcome {
        overflow: hidden;
    }

    .welcome-row {
        margin: 100px 50px;
        display: block;
    }

    .welcome .preview {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }

    .welcome .image {
        width: 200%;
        animation: slideDown 1s ease-out forwards;
    }
}