.about {
    background-color: #0C0D10;
    text-align: center;
    padding: 100px;
    justify-content: center;
}

.about-boxes {
    justify-content: center;
    text-align: center;
    display: flex;
    gap: 50px;
    margin: auto;
}

.box {
    width: 20%;
}

.about .image {
    height: 100px;
    margin-top: 50px;
    margin-bottom: 30px;
}

.about > * {
    margin-bottom: 50px;
}

.about > *:last-child {
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .about {
        padding: 100px 50px;
    }

    .about-boxes {
        display: block;
        flex-direction: column;
    }

    .box {
        width: 70%;
        margin: auto;
    }
}