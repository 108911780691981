* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.footer-body {
    background-color: #3C4658;
    text-align: center;
    padding: 100px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.footer-icons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: start;
}

.icon-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
    text-decoration: none;
}

.icon {
    height: 25px;
    width: 25px;
}

@media (max-width: 768px) {
    .footer-body {
        padding: 50px;
    }
}